<template>
    <div id="template" class="relative sm:w-full md:w-full lg:w-full mx-auto self-center">
        <div class="header flex">
            <div class="back flex items-center justify-center mr-auto" @click="back">
                <feather-icon icon="ArrowLeftIcon"></feather-icon>
            </div>
            <div class="title flex items-center uppercase">New data</div>
            <div class="save flex items-center justify-center ml-auto" @click.prevent="save">
                <feather-icon icon="CheckIcon"></feather-icon>
            </div>
        </div>
        <div class="body sm:w-full md:w-full lg:w-full mx-auto self-center">
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input class="w-full" type="text" label="Full Name" v-model="data.name"
                        :danger="validation.hasError('data.name')" val-icon-danger="clear"
                        :danger-text="validation.firstError('data.name')" />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input class="w-full" type="text" label="Email" v-model="data.email"
                        :danger="validation.hasError('data.email')" val-icon-danger="clear"
                        :danger-text="validation.firstError('data.email')" />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input class="w-full" type="text" label="Phone" v-model="data.phone"
                        :danger="validation.hasError('data.phone')" val-icon-danger="clear"
                        :danger-text="validation.firstError('data.phone')" />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input class="w-full" type="text" label="Amount" v-model="data.amount"
                        :danger="validation.hasError('data.amount')" val-icon-danger="clear"
                        :danger-text="validation.firstError('data.amount')" />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <label class="vs-input--label">
                        Note
                    </label>
                    <vs-textarea v-model="data.note" />
                </div>
            </div>

            <div class="vx-row mb-4">
                <div class="vx-col w-full">
                    <vs-button class="w-full" icon-pack="feather" icon="icon-check" @click.prevent="save">
                        Save</vs-button>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-button class="w-full" type="flat" color="dark" @click="back" icon-pack="feather"
                        icon="icon-arrow-left">
                        Back</vs-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import SimpleVueValidation from 'simple-vue-validator'
    // import axios from 'axios';
    const Validator = SimpleVueValidation.Validator
    Vue.use(SimpleVueValidation)
    export default {
        data() {
            return {
                data: {
                    name: '',
                    email:'',
                    phone:'',
                    amount:'',
                    note:'',
                },
            }
        },
        validators: {
            "data.name": {
                debounce: 500,
                validator: function(value) {
                    return Validator.value(value)
                    .required()
                    .minLength(2)
                    .maxLength(500);
                }
            },
            "data.email": {
                debounce: 500,
                validator: function(value) {
                    return Validator.value(value)
                    .required()
                    .maxLength(255)
                    .email();
                }
            },
            "data.phone": {
                debounce: 500,
                validator: function(value) {
                    return Validator.value(value)
                }
            },
            "data.amount": {
                debounce: 500,
                validator: function(value) {
                    return Validator.value(value)
                    .required()
                    .integer();
                }
            },
        },
        mounted() {

        },
        components: {
            
        },
        methods: {
            back() {
                this.$router.go(-1)
            },
            clearForm() {
                this.data.name = ""
                this.data.email = ""
                this.data.phone = ""
                this.data.amount = ""
                this.data.note = ""
            },
            save() {
                this.$validate().then(result => {
                    if (result) {
                    // this.submitForm();
                        this.actionSave().then(async () => {
                            this.$vs.notify({
                                title: 'Success!',
                                text: 'Saved!',
                                color: 'success',
                                position: 'top-center'
                            })
                            await this.clearForm();
                            this.validation.reset();
                        })
                        .catch(err => {
                            this.$vs.notify({
                                title: 'Oops!',
                                text: err.response ? err.response.data.message :
                                    'Something wrong, ' + err,
                                color: 'danger',
                                position: 'top-center'
                            })
                            this.$vs.loading.close()
                        })
                    }
                });
            },
            actionSave() {
                return new Promise((resolve, reject) => {
                    this.$vs.loading()
                    const send = {
                        name: this.data.name,
                        email: this.data.email,
                        phone: this.data.phone,
                        amount: this.data.amount,
                        note: this.data.note,
                    };
                    this.$http({
                            url: 'v1/payment/store',
                            method: 'POST',
                            data: send
                    })
                    .then(response => {
                        this.$vs.loading.close()
                        const data = response.data.serve
                        resolve(data)
                    })
                    .catch(error => {
                        this.$vs.loading.close()
                        reject(error)
                    })
                })
            },
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/vuesax/_variables.scss";

    #template {
        min-height: 400px;
        background-color: #ffffff;
        box-shadow: 0 0px 20px 0 rgba(0, 0, 0, .05);
        border-radius: .5rem;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;

        .header {
            padding-top: 20px;
            margin-bottom: 40px;

            .help-toggle {
                background: rgba(var(--vs-primary), 1) !important !important;

                span {
                    font-size: 16px;
                    color: rgba(var(--vs-primary), 1) !important !important;
                }
            }

            .back {
                width: 40px;
                height: 40px;
                background: rgba(0, 0, 0, .05);
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    width: 20px;
                    height: 20px;
                }
            }

            .title {
                color: #444444;
                font-weight: 600;
                font-size: 1.3rem;
                line-height: 1;
            }

            .save {
                width: 40px;
                height: 40px;
                background: rgba(var(--vs-primary), 1) !important;
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    color: $white;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .body {
            .now {
                font-size: 25px;
                text-align: center;
                margin-top: 20%;
            }

            .error-msg {
                color: $danger;
                font-size: 11px
            }

            .icons {
                width: 100%;

                li {
                    width: 40px;
                    height: 40px;
                    border-radius: 3px;
                    border: 1px solid transparent;
                    transition: all .5s;
                    cursor: pointer;

                    .feather-icon {
                        width: 20px;
                        height: 20px;
                    }

                    &:hover {
                        background: rgb(255, 255, 255);
                        border: 1px solid rgb(248, 249, 250);
                        box-shadow: 0 0px 10px 0 rgba(0, 0, 0, .05);
                    }

                    &.active {
                        background: rgba(var(--vs-primary), 1) !important;

                        .feather-icon {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
</style>